import axios from 'axios';
import { useState } from 'react';
import {
    Alert,
    Box,
    Button,
    Card,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormLabel,
    FormHelperText,
    Grid,
    IconButton,
    Input,
    Link,
    List,
    ListItem,
    Modal,
    ModalClose,
    ModalDialog,
    Stack,
    Typography,
    Textarea,
  } from '@mui/joy';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import './Modals.css';

export default function InviteStudentModal({ open, onClose, orgId, courseId, onInviteSuccess }) {
  const [studentEmails, setStudentEmails] = useState([]);
  const [newEmail, setNewEmail] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('success');
  const [emailError, setEmailError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const closeWithReset = () => {
    setStudentEmails([]);
    setNewEmail('');
    setAlertMessage('');
    setEmailError('');
    onClose();
  };

  const isValidEmail = (email) => {
    // Basic email validation regex
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const processEmails = (newEmails) => {
    // Convert input to array if it isn't already
    const emailsArray = Array.isArray(newEmails) 
      ? newEmails 
      : newEmails.split(/[\n,]+/)
          .map(email => email.trim())
          .filter(email => email.length > 0);

    // Create Sets to easily check for duplicates
    const currentEmailsSet = new Set(studentEmails);

    // Categorize emails
    const duplicateEmails = emailsArray.filter(email => currentEmailsSet.has(email));
    const validEmails = emailsArray.filter(email => 
      isValidEmail(email) && !currentEmailsSet.has(email)
    );
    const invalidEmails = emailsArray.filter(email => !isValidEmail(email));

    // Also check for duplicates within the new emails
    const internalDuplicates = emailsArray.filter((email, index, self) => 
      self.indexOf(email) !== index
    );

    // Combine both types of duplicates
    const allDuplicates = [...new Set([...duplicateEmails, ...internalDuplicates])];

    // Construct error messages
    let errorMessages = [];
    if (invalidEmails.length > 0) {
      errorMessages.push(`Invalid email${invalidEmails.length > 1 ? 's' : ''}: ${invalidEmails.join(', ')}`);
    }
    if (allDuplicates.length > 0) {
      errorMessages.push(`Duplicate email${allDuplicates.length > 1 ? 's' : ''}: ${allDuplicates.join(', ')}`);
    }

    return {
      validEmails,
      errorMessage: errorMessages.join('. '),
      hasValidEmails: validEmails.length > 0
    };
  };

  const handleAddEmail = () => {
    if (!newEmail.trim()) {
      setEmailError('Please enter at least one email address');
      return;
    }

    const { validEmails, errorMessage, hasValidEmails } = processEmails(newEmail);

    if (hasValidEmails) {
      setStudentEmails([...studentEmails, ...validEmails]);
      setNewEmail('');
    }

    setEmailError(errorMessage);
    if (!errorMessage) {
      setAlertMessage('');
    }
  };

  const handleRemoveEmail = (email) => {
    setStudentEmails(studentEmails.filter(e => e !== email));
  };

  const handleCSVUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target.result;
        // First deduplicate the incoming emails
        const uniqueEmails = [...new Set(
          content
            .split(/[\n,]+/)
            .map(email => email.trim())
            .filter(email => email.length > 0)
        )];

        const result = processEmails(uniqueEmails);

        if (result.hasValidEmails) {
          // Ensure we're not adding duplicates to the existing list
          const newUniqueEmails = result.validEmails.filter(
            email => !studentEmails.includes(email)
          );
          
          setStudentEmails(prevEmails => [...prevEmails, ...newUniqueEmails]);
          setAlertMessage(`Successfully added ${newUniqueEmails.length} unique email${newUniqueEmails.length > 1 ? 's' : ''} from your csv`);
          setAlertType('success');
        }

        if (result.errorMessage) {
          setEmailError(result.errorMessage);
        } else {
          setEmailError('');
        }
      };
      reader.readAsText(file);
    }
    // Reset the file input so the same file can be uploaded again if needed
    event.target.value = '';
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    await handleSendInvites();
  };

  const handleSendInvites = async () => {
    setAlertMessage('');

    if (studentEmails.length === 0) {
      setAlertMessage('Please add at least one student email before sending invites');
      setAlertType('danger');
      return;
    }

    try {
      setIsLoading(true);
      const responses = await Promise.all(studentEmails.map(email => 
        axios.post('/api/invite_user_collaborator', {
          studentName: email.split('@')[0], // Using part of email as name
          studentOrg: orgId,
          studentEmail: email,
          courseId: courseId
        })
        .catch(error => ({ status: 'error', error: error.response?.data?.errors?.error || error.message }))
      ));
      
      const successfulInvites = [];
      const failedInvites = [];

      responses.forEach((response, index) => {
        if (response.data && response.status === 200) {
          successfulInvites.push(studentEmails[index]);
        } else if (response.status === 409) {
          failedInvites.push(`${studentEmails[index]} (already exists)`);
        } else {
          failedInvites.push(`${studentEmails[index]} (${response.error || 'failed'})`);
        }
      });

      setIsLoading(false);

      if (failedInvites.length > 0) {
        setAlertMessage(`Failed to invite: ${failedInvites.join(', ')}`);
        setAlertType('danger');
      } else if (successfulInvites.length > 0) {
        setAlertMessage(`Successfully invited: ${successfulInvites.join(', ')}`);
        setAlertType('success');
        if (onInviteSuccess) {
          onInviteSuccess('Successfully invited students');
        }
        setTimeout(() => {
          onClose();
          setStudentEmails([]);
          setAlertMessage('');
        }, 2000);
      }

    } catch (error) {
      setIsLoading(false);
      if (error.response && error.response.status === 409) {
        setAlertMessage(`User already exists: ${error.response.data.email}`);
        setAlertType('danger');
      } else {
        setAlertMessage('An error occurred while sending invitations. Please try again.');
        setAlertType('danger');
        console.error('Error sending invitations:', error);
      }
    }
  };

  return (
      <Modal open={open} onClose={closeWithReset}>
        <ModalDialog
          layout='fullscreen'
          variant="outlined"
          size="lg"
          className="modal-container"
        >
          <DialogTitle className="modal-name">Invite Students</DialogTitle>
          <ModalClose
            aria-label="Close Dialog"
           />
          <DialogContent>
          <form onSubmit={handleSubmit} aria-label="Student invitation form">
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                spacing={3}
                mb={4}
              >
                  <Typography mb={2}>Enter student emails or upload a csv</Typography>
                  <Box component="div" role="group" aria-label="CSV upload section">
                    <Input
                      type="file"
                      accept=".csv"
                      onChange={handleCSVUpload}
                      id="csv-upload"
                      aria-label="Upload CSV file"
                      style={{ 
                        display: 'none',
                        '&::file-selector-button': {
                          display: 'none'
                        }
                      }}
                    />
                    <Button
                      htmlFor="csv-upload"
                      variant="soft"
                      size="sm"
                      tabIndex={0}
                      onClick={(e) => {
                        document.getElementById('csv-upload').click();
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          document.getElementById('csv-upload').click();
                        }
                      }}
                    >
                      Upload CSV
                    </Button>
                  </Box>
                <Stack
                  direction={{ xs:'column', sm:'row'}}
                  justifyContent="flex-start"
                  alignItems="flex-end"
                  spacing={1}
                  sx={{width: "100%", padding: '0'}}
                  component="fieldset"
                  border="none"
                >
                  <FormControl error={!!emailError} sx={{width: "100%"}}>                 
                    <FormLabel>Emails</FormLabel>
                    <FormHelperText>
                      {emailError || "Add multiple emails separated with commas or a line-break"}
                    </FormHelperText> 
                    <Textarea
                      variant="outlined"
                      maxRows={6}
                      color={emailError ? "danger" : "primary"}
                      value={newEmail}
                      onChange={(e) => setNewEmail(e.target.value)}
                    />
                  </FormControl>
                  <Button
                    type="button"
                    variant="outlined"
                    endDecorator={<AddIcon />}
                    onClick={handleAddEmail} 
                  >
                    Add
                  </Button>
                </Stack>
                <Divider/>
                {studentEmails.length > 0 && (
                  <Box
                    component="section" 
                    tabIndex={0}  // Make it keyboard focusable
                    role="region"  // Adds semantic meaning for screen readers
                    aria-label="Added email addresses" 
                    className="scrollable-box"
                  >
                    <List 
                      aria-label="List of added emails"
                      sx={{  
                        "--ListItem-paddingX": "0px",
                        "--ListItem-paddingY": "8px",
                        maxHeight: `calc(${5} * var(--ListItem-minHeight) + var(--ListItem-gap) + 2 * var(--ListItem-paddingY))`,
                        overflowY: "auto"
                      }}>
                      {studentEmails.map((email, index) => (
                          <ListItem
                                key={index}
                                sx={{width:'100%'}}
                                endAction={
                                  <IconButton 
                                    aria-label="Remove" 
                                    size="sm" 
                                    color="danger"
                                    onClick={() => handleRemoveEmail(email)}
                                    disabled={isLoading}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                }
                              >
                                {email}
                          </ListItem>
                      ))}
                    </List>
                  </Box>
                )}
                {alertMessage && (
                  <Alert color={alertType} sx={{ mb: 2 }} role="alert" aria-live="polite">
                    {alertMessage}
                  </Alert>
                )}
              </Stack>
              </form>
            </DialogContent>
            <DialogActions className="dialog-actions-right">
                      <Button
                        type="button"
                        variant="outlined"
                        onClick={closeWithReset}
                        >
                          Cancel
                      </Button>
                    {isLoading ? (
                      <Button 
                        type="submit"
                        variant="solid" 
                        loading
                        loadingPosition="end"
                      >
                        Sending...
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        variant="solid" 
                        onClick={handleSendInvites}
                        endDecorator={<SendIcon />}
                        >
                        Send Invites
                      </Button>
                    )}
            </DialogActions>
        </ModalDialog>
      </Modal>
  );
}
