import axios from 'axios';
import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Modal,
  ModalClose,
  ModalDialog,
  Snackbar,
  Stack,
  Textarea,
  Typography,
  FormHelperText
} from '@mui/joy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import './Modals.css';

export default function CreateNoteModal({ open, onClose, refresh, edit = false, file_id = null, note_template = null, question_id = null}) {
  const [title, setTitle] = useState('');
  const [noteText, setNoteText] = useState('');
  const [errorType, setErrorType] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    color: 'primary',
  });
  const [loading, setLoading] = useState(false);
  const snackbarRef = useRef(null);

  useEffect(() => {
    if (open) {
      if (edit && file_id) {
        setLoading(true);
        axios.get(`/api/get_note_text/${file_id}`)
        .then(response => {
          setTitle(response.data.title);
          setNoteText(response.data.text);
        })
        .catch(error => {
        //   console.error('Error fetching note:', error);
          setTitle('');
          setNoteText('');
          setSnackbar({
            open: true,
            message: 'Failed to load note',
            color: 'danger',
          });
        })
        .finally(() => {
          setLoading(false);
        });
      } else if (note_template && note_template.title && note_template.text) {
        setTitle(note_template.title);
        setNoteText(note_template.text);
      } else {
        setTitle('');
        setNoteText('');
      }
    }
  }, [open, edit, file_id]);

  const handleSave = async () => {
    if (!title.trim()) {
      setErrorType('title');
      setErrorMessage('Title is required');
      return;
    }

    if (!noteText.trim()) {
      setErrorType('noteText');
      setErrorMessage('Note text is required');
      return;
    }

    const MAX_TEXT_LENGTH = 2500;
    if (noteText.length > MAX_TEXT_LENGTH) {
      setErrorType('noteText');
      setErrorMessage(`Note text cannot exceed ${MAX_TEXT_LENGTH} characters`);
      return;
    }
    
    setErrorType(null);
    setErrorMessage('');

    try {
      let uploadResponse;
      if (edit) {
        uploadResponse = await axios.post(`/api/replace_note/${file_id}`, {
          new_title: title,
          new_text: noteText,
          question_id: question_id,
        }, {
          headers: { 'Content-Type': 'application/json' }
        });
      } else {
        uploadResponse = await axios.post('/api/upload_note', {
            title: title,
            text: noteText,
            question_id: question_id,
          }, {
            headers: { 'Content-Type': 'application/json' }
          });
      }


      if (uploadResponse.status === 200) {
        if (question_id) {
          refresh(uploadResponse.data.result.file_id);
        } else {
          refresh();
        }
        handleClose();
      } else {
        setSnackbar({
        open: true,
        message: 'Failed to save note',
        color: 'danger',
        });
      }
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to save note',
        color: 'danger',
      });
    }
  };

  const handleClose = () => {
    setTitle('');
    setNoteText('');
    setErrorType(null);
    setErrorMessage('');
    onClose();
  };

  return (
    <React.Fragment>
      <Modal open={open} onClose={handleClose}>
        <ModalDialog
          layout="fullscreen"
          variant="outlined"
          size="lg"
          className="modal-container"
        >
          <DialogTitle className="modal-name">{edit ? 'Edit' : 'Create'} Note</DialogTitle>
          <ModalClose
            aria-label="Close Dialog"
           />
          <DialogContent>
            <Grid xs={12}>
              {loading ? (
                <Typography>Loading...</Typography>
              ) : (
                <Stack 
                  direction="column" 
                  justifyContent="center"
                  alignItems="flex-start"
                  spacing={3} 
                  mb={4}
                >
                  <FormControl sx={{ width: '100%' }} error={errorType === 'title'}>
                    <FormLabel>Title</FormLabel>
                    <Input
                      variant="outlined"
                      color="primary"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      sx={{ width: "100%" }}
                    />
                    {errorType === 'title' && (
                      <FormHelperText>{errorMessage}</FormHelperText>
                    )}
                  </FormControl>
                  <FormControl sx={{ width: '100%', height: '50vh' }} error={errorType === 'noteText'}>
                    <FormLabel>Note Text</FormLabel>
                    <Textarea
                      variant="outlined"
                      color="primary"
                      value={noteText}
                      onChange={(e) => setNoteText(e.target.value)}
                      minRows={15}
                      maxRows={15}
                    />
                    {errorType === 'noteText' && (
                      <FormHelperText>{errorMessage}</FormHelperText>
                    )}
                  </FormControl>
                </Stack>
              )}
            </Grid>
            </DialogContent>
            <DialogActions className="dialog-actions-right">
                <Button variant="outlined" onClick={handleClose}>
                  Cancel
                </Button>
                <Button variant="solid" onClick={handleSave} disabled={loading}>
                  Save
                </Button>
            </DialogActions>
        </ModalDialog>
      </Modal>
      <Snackbar
        open={snackbar.open}
        color={snackbar.color}
        size="md"
        variant="soft"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        startDecorator={snackbar.color === 'success' ? <CheckCircleIcon /> : <ErrorOutlineIcon />}
        ref={snackbarRef}
        tabIndex={-1}
        role="status"
        aria-live="polite"
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
      >
        {snackbar.message}
      </Snackbar>
    </React.Fragment>
  );
}
