import React, { useState, useEffect } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Stack,
  Typography,
  Snackbar,
  Card
} from '@mui/joy';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { slugify } from '../UrlHandler';
import { getOrgName } from '../../OrgRetrieveAPI';

export default function CourseUrlFields({ courseUrl = "", errorMessage = "", isSubmitted = false, onValidChange = () => {}, onUrlChange = () => {} }) {
  // Url Management
  const rootUrl = "app.alldayta.com";
  const [slugifiedOrgName, setSlugifiedOrgName] = useState("");
  const [courseUrlInput, setCourseUrlInput] = useState("");
  const [fullUrl, setFullUrl] = useState("");
  // Flags and alerts
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [urlError, setUrlError] = useState("");

  // Handles collecting the org name
  useEffect(() => {
    const fetchOrgName = async () => {
      try {
        const name = await getOrgName();
        setSlugifiedOrgName(slugify(name));
      } catch (error) {
        console.error('Error fetching organization name:', error);
        // Handle the error appropriately in your UI
      }
    };

    fetchOrgName();
  }, []);
  
  // Handles updating course part of the url when the course name changes in parent
  useEffect(() => {
    const slugifiedCourseUrl = slugify(courseUrl);
    setFullUrl(`${rootUrl}/${slugifiedOrgName}/${slugifiedCourseUrl}`);
    if (courseUrl) {
      setCourseUrlInput(slugifiedCourseUrl);
    }
  }, [rootUrl, slugifiedOrgName, courseUrl]);

  // Handles updating the error message when the isSubmitted flag changes in parent
  useEffect(() => {
    setUrlError(errorMessage);
  }, [isSubmitted, errorMessage]);

  const handleCourseUrlChange = (e) => {
    // Keep original text in input
    const newEditablePart = e.target.value;
    setCourseUrlInput(newEditablePart);
    const newFullUrl = `${rootUrl}/${slugifiedOrgName}/${slugify(newEditablePart)}`;
    setFullUrl(newFullUrl);
    onValidChange(newFullUrl);
    setUrlError("");
    onUrlChange(true); // Notify parent component that URL has changed
  };

  // Url Alert Body
  const copyToClipboard = async (urlToCopy) => {
    try {
      await navigator.clipboard.writeText(urlToCopy);
      setSnackbarOpen(true);
    } catch (err) {
      //console.error('Failed to copy: ', err);
    }
  };

  return (
    <>
      <FormControl error={!!urlError}>
        <FormLabel>Course URL (Student Access)</FormLabel>
          <Input
            variant="outlined"
            color={urlError ? "danger" : "primary"}
            value={courseUrlInput}
            onChange={handleCourseUrlChange}
            sx={{ display: 'flex', flexGrow: '1' }}
          />
        {urlError && (
          <FormHelperText sx={{ color: 'danger' }}>
            {urlError}
          </FormHelperText>
        )}
      </FormControl>
      <Card variant="soft" sx={{ p: 1.5 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" width={"100%"}>
          <Typography level="body-sm" fontWeight={"var(--joy-fontWeight-md)"}>
            <strong>Your URL:</strong> {fullUrl}
          </Typography>
          <Button
            variant="plain"
            color="neutral"
            onClick={() => copyToClipboard(fullUrl)}
            endDecorator={<ContentCopyIcon />}
            size="sm"
          >
            Copy
          </Button>
        </Stack>
      </Card>
      <Snackbar
        autoHideDuration={3000}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        color="success"
        variant="soft"
        role="alert"
        aria-live="polite"
        data-cy="copy-success-snackbar"
      >
        URL copied to clipboard!
      </Snackbar>
    </>
  );
}