import { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Stack,
  Typography,
} from '@mui/joy';
import Empty from '../assets/FilesEmptyState.svg';
import { Add as AddIcon } from '@mui/icons-material';

export const FullScreenEmptyState = () => (
  <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      mt={8}
      mb={8}
    >
    <Stack 
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={1}>
      <Typography level="h2">No Courses Found</Typography>
      <Typography level="body-lg">Please create a course to get started.</Typography>
    </Stack>
    <Button
        endDecorator={<AddIcon />}
        component="a"
        href="/create-course"
        variant="solid"
        color="primary"
    >
        Create a Course
    </Button>
  </Stack>
);

export const NoFiles = () => (
  <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      mt={4}
      mb={8}
    >
    <img src={Empty} alt="" width="150" height="129"/>
    <Typography level="title-lg">No Files</Typography>
    <Typography>Upload a file to start creating your All Day TA</Typography>
  </Stack>
);
