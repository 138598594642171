import React from 'react';
import {
  Button,
  Grid,
  Stack,
  Typography
} from '@mui/joy';
import Container from './Container';
import HelpIcon from '@mui/icons-material/HelpOutline';
import LoadingIcon from '../assets/LoadingLogo.gif';

// Helpers
const buttons = {
  reload: (
    <Button variant="solid" onClick={() => window.location.reload()}>
      Retry
    </Button>
  ),
  contactSupport: (
    <Button
      variant="outlined"
      component="a"
      href="https://alldayta.com/support"
      startDecorator={<HelpIcon />}
    >
      Contact Support
    </Button>
  ),
  shareToProf: (
    <Button
      variant="outlined"
      component="a"
      href="https://www.alldayta.com/for-students"
      startDecorator={<HelpIcon />}
    >
      Share with Professor
    </Button>
  ),
}

const GenericBody = ({ title, message, button }) => (
  <Container>
    <Grid container spacing={2} mt={1} xs={12} sx={{ alignItems: 'center', justifyContent: 'center' }}>
      <Stack direction="column" alignItems="center" justifyContent="center" gap={2} sx={{ textAlign: 'center' }}>
        <Typography level="h2">{title}</Typography>
        <Typography level="body-lg">{message}</Typography>
        {button}
      </Stack>
    </Grid>
  </Container>
)

// Custom States
export const Initializing = ({ message = "Loading All Day TA" }) => (
    <Grid container spacing={2} mt={1} xs={12} sx={{ alignItems: 'center', justifyContent: 'center' }}>
      <Stack direction="column" alignItems="center" justifyContent="center" gap={2}>
        <img src={LoadingIcon} alt="" style={{ width: '6rem', height: '6rem', display: 'block' }} />
        <Typography level="title-lg">{message}</Typography>
      </Stack>
    </Grid>
  );

export const CourseNotFound = () => (
  <GenericBody 
    title={"Course Not Found"}
    message={"We couldn't find a course associated with this URL. Please check the URL and try again."}
    button={buttons.contactSupport}
  />
);

export const GeneralError = () => (
  <GenericBody 
    title={"Error"}
    message={"An error occurred while loading the chatbot. Please try again later."}
    button={buttons.reload}
  />
);

export const SubscriptionExpired = () => (
  <GenericBody 
    title={"This course is currently inactive"}
    message={"Let your professor know you want to use All Day TA for your course."}
    button={buttons.shareToProf}
  />
);

export const CourseInactive = () => (
  <GenericBody 
    title={"Temporarily Unavailable"}
    message={"Your professor has temporarily disabled this course. If you think this is an error, please contact your professor."}
  />
);