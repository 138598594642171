import React from 'react';
import { 
Button 
} from '@mui/joy';

const FootnoteButton = ({ 
    footnoteName, 
    setShowFootnotes, 
    setActiveFootnote, 
    scrollToFootnote 
}) => {
    return (
        <Button
            onClick={() => {
                setShowFootnotes(true);
                setActiveFootnote(footnoteName);
                setTimeout(() => scrollToFootnote(footnoteName), 100);
            }}
            variant="soft"
            color="primary"
            size="sm"
            aria-label={`Read footnote ${footnoteName}`}
            sx={{ 
                alignItems: 'center',
                borderRadius: '1.5rem',
                boxSizing: 'border-box',
                display: 'inline-flex',
                position: 'relative',
                justifyContent: 'center',
                fontSize: '0.8rem',
                fontWeight: '500',
                lineHeight: '1.125rem',
                minHeight: '1.25rem',
                paddingInline: '0.375rem',
                paddingBlock: '0rem',
                marginTop: '-0.5rem',
                verticalAlign: 'middle'
            }}
        >
            {footnoteName}
        </Button>
    );
};

export default FootnoteButton;