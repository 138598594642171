import React from 'react';
import 'katex/dist/katex.min.css';
import { InlineMath, BlockMath } from 'react-katex';

const LatexRenderer = ({ text }) => {
  const renderLatex = (input) => {
    // Match numbers with exponents not already in LaTeX delimiters but not footnote-style numbers in brackets
    const parts = input.split(/((?<!\\)\\\[[\s\S]*?(?<!\\)\\\]|(?<!\\)\\\(.*?(?<!\\)\\\)|(?<!\[)\d+\^-?\d+(?!\]))/g);
    
    return parts.map((part, index) => {
      if (part.startsWith('\\[') && part.endsWith('\\]')) {
        // Block LaTeX
        return <BlockMath key={index} math={part.slice(2, -2)} />;
      } else if (part.startsWith('\\(') && part.endsWith('\\)')) {
        // Inline LaTeX
        return <InlineMath key={index} math={part.slice(2, -2)} />;
      } else if (/^\d+\^-?\d+$/.test(part)) {
        // Convert bare number^exponent to LaTeX
        const [base, exponent] = part.split('^');
        return <InlineMath key={index} math={`${base}^{${exponent}}`} />;
      } else {
        // Regular text - render as is, preserving newlines
        return part.split('\n').map((line, lineIndex) => (
          <React.Fragment key={`${index}-${lineIndex}`}>
            {line}
            {lineIndex < part.split('\n').length - 1 && <br />}
          </React.Fragment>
        ));
      }
    });
  };

  return <>{renderLatex(text)}</>;
};

export default LatexRenderer;