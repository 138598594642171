import React, {
  useState,
  useEffect,
  useRef,
} from "react";
import {
  Button,
  Card,
  FormControl,
  FormLabel,
  FormHelperText,
  Snackbar,
  Stack,
  Textarea,
  Typography,
} from "@mui/joy";

export default function ChatbotInputForm({ askQuestion, isAuthenticated = true }) {
  const [query, setQuery] = useState("");
  const [errorText, setErrorText] = useState("");
  const maxLength = 500;
  const textareaRef = useRef(null);
  const announcerRef = useRef(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [query]);

  const handleAskQuestion = () => {
    if (!isAuthenticated) {
      setErrorText('Please login to ask questions.');
      return;
    }

    if (query.trim() === '') {
      setErrorText('Please enter a question before submitting.');
      return;
  }

    if (announcerRef.current) {
      announcerRef.current.textContent = "Processing your question. Please wait for the answer.";
    }

    const q = query;
    askQuestion(q);
    setQuery('');
  }

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey && query.trim() !== "") {
      event.preventDefault();
      handleAskQuestion();
    }
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setErrorText("");
    if (inputValue.length <= maxLength) {
      setQuery(inputValue);
    } else {
      setQuery(inputValue.substring(0, maxLength));
      setSnackbarOpen(true);
    }
  };

  const handlePaste = (event) => {
    const pastedText = event.clipboardData.getData("text");
    const newText = query + pastedText;
    if (newText.length <= maxLength) {
      setQuery(newText);
    } else {
      setSnackbarOpen(true);
      setQuery(newText.substring(0, maxLength));
    }
    event.preventDefault();
  };

  return (
    <>
      <Card
        variant="soft"
        orientation="vertical"
        sx={{ boxSizing: "border-box", boxShadow: "sm" }}
      >
        <FormControl>
          <FormLabel>Ask a question</FormLabel>
          <Textarea
            name="outlined"
            color="primary"
            value={query}
            onChange={handleInputChange}
            onPaste={handlePaste}
            onKeyPress={handleKeyPress}
            ref={textareaRef}
            sx={{
              minHeight: "4rem",
              marginBottom: "0rem",
              overflow: "hidden",
              resize: "none",
            }}
          />
          {errorText && <FormHelperText>{errorText}</FormHelperText>}
        </FormControl>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          {/*<Button
                value="md"
                variant="outlined"
                endDecorator={<ArrowForwardIcon />}
                color="neutral"
              >
                Ask as follow up
              </Button>*/}
          <Typography level="component-sm">
            {query.length}/{maxLength} characters
          </Typography>
          <Button
            variant="solid"
            size="md"
            onClick={handleAskQuestion}
            aria-label={`Ask question: ${query}`}
          >
            Ask
          </Button>
        </Stack>
      </Card>
      <div
        ref={announcerRef}
        aria-live="polite"
        aria-atomic="true"
        style={{
          position: "absolute",
          height: "1px",
          width: "1px",
          overflow: "hidden",
          clip: "rect(1px, 1px, 1px, 1px)",
        }}
      />
      <Snackbar
        autoHideDuration={8000}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        color="success"
        variant="soft"
        role="alert"
        aria-live="polite"
        tabIndex={-1}
        data-testid="char-limit-warning"
      >
        You have exceeded 500 characters. Your text has been truncated.
      </Snackbar>
    </>
  );
}
