import React, { useState, useEffect, useCallback } from 'react';
import { 
  FormControl,
  FormHelperText,  
  FormLabel, 
  Input,
  Alert,
  Button,
  Link,
} from '@mui/joy';

const ProfessorContactFields = ({ 
  initialName = '',
  initialEmail = '',
  onValidChange,
  isSubmitted = false,
  restrictEmails = false
}) => {
  const [professorName, setProfessorName] = useState(initialName);
  const [professorEmail, setProfessorEmail] = useState(initialEmail);
  const [errors, setErrors] = useState({
    professorName: false,
    professorEmail: false
  });
  const [showStudentEmailAlert, setShowStudentEmailAlert] = useState(false);

  const isValidEmail = (email) => {
    // Basic email validation regex
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const isBlockedEmail = useCallback((email) => {
    if (restrictEmails) {
      const blockedEmails = [
        'student',
        'étudiant',
        'alumno',
        'estudiant'
      ];

      // Check if the email is in the blocked list
      const lowerCaseEmail = email.toLowerCase();
      return blockedEmails.some(blockedWord => lowerCaseEmail.includes(blockedWord));
    }
    return false;
  }, [restrictEmails]);

  const nameIsError = useCallback((name) => {
    return !name.trim();
  }, []);

  const emailIsError = useCallback((email) => {
    return !email.trim() || !isValidEmail(email) || (isBlockedEmail(email));
  }, [isBlockedEmail]);

  // Handle professor name change with validation
  const handleProfessorNameChange = (e) => {
    const newName = e.target.value;
    setProfessorName(newName);
    
    if (errors.professorName) {
      setErrors(prev => ({ ...prev, professorName: false }));
    }

    // Notify parent component of valid changes
    onValidChange({
      name: newName,
      email: professorEmail,
      isValid: Boolean(!nameIsError(newName) && !emailIsError(professorEmail))
    });
  };

  // Handle professor email change with validation
  const handleProfessorEmailChange = (e) => {
    const newEmail = e.target.value;
    setProfessorEmail(newEmail);
    
    if (errors.professorEmail) {
      setErrors(prev => ({ ...prev, professorEmail: false }));
    }

    // Notify parent component of valid changes
    onValidChange({
      name: professorName,
      email: newEmail,
      isValid: Boolean(!nameIsError(professorName) && !emailIsError(newEmail))
    });
  };

  // Update errors when isSubmitted changes
  useEffect(() => {
    if (isSubmitted) {
      const newErrors = {
        professorName: nameIsError(professorName),
        professorEmail: emailIsError(professorEmail)
      };
      setErrors(newErrors);
      if (newErrors.professorEmail && isBlockedEmail(professorEmail)) {
        setShowStudentEmailAlert(true);
      }
    }
  }, [isSubmitted, professorEmail, professorName, nameIsError, emailIsError, isBlockedEmail]);

  // Update form when initial values change
  useEffect(() => {
    setProfessorName(initialName);
    setProfessorEmail(initialEmail);
  }, [initialName, initialEmail]);

  return (
    <>
      <FormControl error={errors.professorName}>
        <FormLabel>Name</FormLabel>
        <Input 
          variant="outlined"
          color={errors.professorName ? "danger" : "primary"}
          required 
          value={professorName}
          onChange={handleProfessorNameChange}
        />
        <FormHelperText>
          {errors.professorName ? "Professor name is required" : null}
        </FormHelperText>
      </FormControl>

      <FormControl error={errors.professorEmail}>
        <FormLabel>Email</FormLabel>
        <Input 
          variant="outlined"
          color={errors.professorEmail ? "danger" : "primary"}
          required
          value={professorEmail}
          onChange={handleProfessorEmailChange}
          type="email"
        />
        <FormHelperText>
          {
          errors.professorEmail && !professorEmail.trim() ? "Professor email is required" : 
          errors.professorEmail && !isValidEmail(professorEmail) ? "Professor email is invalid" :
          errors.professorEmail && isBlockedEmail(professorEmail) ? "This sign-up is for professors only" : null
          }
        </FormHelperText>
      </FormControl>
      {showStudentEmailAlert && (
        <Alert 
          sx={{ mt: 2, mb: 2 }} 
          variant="outlined"
          color="primary"
          role="alert"
          aria-live="polite"
          endDecorator={
              <Button variant="plain" size="sm" color="primary" onClick={() => setShowStudentEmailAlert(false)}>
                  Dismiss
              </Button>
          }
        >
          Want to use All Day TA for your course?
          <Link level="body-sm" href="https://www.alldayta.com/for-students" target="_blank" rel="noopener noreferrer" underline="always" variant="plain">Share with Professor</Link>
        </Alert>
      )}
    </>
  );
};

export default ProfessorContactFields;