import axios from 'axios';
import React, { useState, useEffect } from 'react';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  Link,
  Stack,
  Typography,
  FormHelperText,
  AutocompleteOption
} from '@mui/joy';
import Container from '../components/Container';
import ProfessorContactFields from '../components/form-elements/ProfessorContactFields';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { getAllOrganizations } from '../OrgRetrieveAPI';
import Logo from '../assets/logo.svg';
import LogoMobile from '../assets/logoMobile.svg';

export default function ProfessorSignUp() {
  const [professorOrg, setProfessorOrg] = useState(null);
  const [professorFaculty, setProfessorFaculty] = useState(null);
  const [professorDepartment, setProfessorDepartment] = useState(null);
  const [organizationOptions, setOrganizationOptions] = useState([]);
  const [facultyOptions, setFacultyOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    isValid: false
  });
  const [errors, setErrors] = useState({
    professorOrg: false
  });

  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        const data = await getAllOrganizations();
        console.log("Fetched organization options:", data);
        setOrganizationOptions(data);
      } catch (error) {
        console.error('Error fetching organizations:', error);
      }
    };

    fetchOrganizations();
  }, []);

  const getFaculties = async (org_id) => {
    try {
      if (!org_id) return;

      const response = await axios.get(`/api/faculty?org_id=${org_id}`);

      const processedData = response.data.faculties
        .map((item) => ({
          id: item.id,
          org_id: item.org_id,
          name: item.name,
          owner_id: item.owner_id,
          owner_name: item.owner_name,
          owner_email: item.owner_email,
        }))
        .sort((a, b) => a.name.localeCompare(b.name));

      setFacultyOptions(processedData);
    } catch (error) {
      console.error("Error fetching faculties:", error);
    }
  };

  const getDepartments = async (faculty_id) => {
    try {
      if (!faculty_id) return;

      const response = await axios.get(
        `/api/department?faculty_id=${faculty_id}`
      );

      const processedData = response.data.departments
        .map((item) => ({
          id: item.id,
          faculty_id: item.faculty_id,
          name: item.name,
          owner_id: item.owner_id,
          owner_name: item.owner_name,
          owner_email: item.owner_email,
        }))
        .sort((a, b) => a.name.localeCompare(b.name)); // alphabetical sort;

      setDepartmentOptions(processedData);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  const handleFacultyUpdate = (newValue) => {
    setProfessorFaculty(newValue);
    handleDepartmentUpdate(null);
    getDepartments(newValue?.id);
  };

  const handleDepartmentUpdate = (newValue) => {
    setProfessorDepartment(newValue);
  };

  const handleFormChange = ({ name, email, isValid }) => {
    setFormData({ name, email, isValid });
  };

  const handleSave = async () => {
    console.log('handleSave function called');
    setIsSubmitted(true);

    if (!formData.isValid) {
      return;
    }

    // Trim whitespace and convert email to lowercase
    const trimmedEmail = formData.email.trim().toLowerCase();

    const adminData = {
      professorName: formData.name.trim(),
      professorOrg,
      professorFaculty: professorFaculty?.id,
      professorDepartment: professorDepartment?.id,
      professorEmail: trimmedEmail
    };
    console.log('adminData prepared:', adminData);
  
    const formDataObj = new FormData();
    formDataObj.append('adminData', JSON.stringify(adminData));
    console.log('formData created:', formDataObj);
  
    try {
      const response = await axios.post('/api/create_admin', formDataObj, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.status === 200) {
        setMessage('Admin created successfully');
        setIsError(false);
        console.log('Server response:', response);
    
        // Reset form
        setFormData({ name: '', email: '', isValid: false });
        setProfessorOrg(null);
        setProfessorFaculty(null);
        setProfessorDepartment(null);
        setIsSubmitted(false);
      }
      
    } catch (error) {
      setMessage('Error creating admin: ' + error.response.data.error);
      setIsError(true);
      console.error('Error details:', error);
    }
  };

  return (
    <Box>
      <header className="header-wrapper">
        <Box className="left-wrapper">
            <a href="/login/admin"><img src={Logo} alt="All Day TA" className="logo" /></a>
            <a href="/login/admin"><img src={LogoMobile} alt="All Day TA" className="logo-mobile" /></a>
        </Box>
      </header>
      <Container>
        <Grid container xs={12} mx='auto' spacing={4} justifyContent="space-around"
            sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '60vh', // Ensure full viewport height
          }}>
            <Grid xs={12} sm={8} md={6}>
              <Stack direction="column" spacing={3}>
              <Typography level="h1">Professor Sign Up</Typography>
              {message && (
                  <Alert
                      color={isError ? 'danger' : 'success'} 
                      sx={{ mb: 2 }}
                      role="alert"
                      aria-live="polite"
                      >
                      {message}
                  </Alert>
              )}
              <Typography level="body-md">Enter the professor's information to send them an invite email to create their password.</Typography>
              <FormControl>
                <FormLabel>Organization</FormLabel>
                <Autocomplete
                  variant="outlined"
                  color={errors.professorOrg ? "danger" : "primary"}
                  options={organizationOptions}
                  getOptionLabel={(option) => option.org_name}
                  placeholder="Search organizations"
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setProfessorOrg(newValue.org_id); // Store the org_id instead of the name
                      setProfessorFaculty(null);
                      setProfessorDepartment(null);
                      getFaculties(newValue.org_id);
                    } else {
                      setProfessorOrg('');
                    }
                    if (errors.professorOrg) {
                      setErrors(prev => ({ ...prev, professorOrg: false }));
                    }
                  }}
                  value={professorOrg ? organizationOptions.find(org => org.org_id === professorOrg) : null}
                  renderOption={(props, option) => {
                    const { key, ...restProps } = props; // Extract and exclude the key from props
                    return (
                      <AutocompleteOption key={key} {...restProps} sx={{ maxHeight: 200, overflow: 'auto' }}>
                        {option.org_name}
                      </AutocompleteOption>
                    );
                  }}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Faculty</FormLabel>
                <Autocomplete
                  variant="outlined"
                  disabled={!professorOrg}
                  options={facultyOptions}
                  value={professorFaculty}
                  placeholder="Search faculties"
                  getOptionLabel={(option) => option.name}
                  onChange={(e, newValue) => handleFacultyUpdate(newValue)}
                  renderOption={(props, option) => {
                    const { key, ...restProps } = props; // Extract and exclude the key from props
                    return (
                      <AutocompleteOption key={key} {...restProps}>
                        {option.name}
                      </AutocompleteOption>
                    );
                  }}
                />
                <FormHelperText>
                  {!professorOrg ? "Select an Organization to enable" : ""}
                </FormHelperText>
              </FormControl>
              {/* Department */}
              <FormControl>
                <FormLabel>Department</FormLabel>
                <Autocomplete
                  variant="outlined"
                  disabled={!professorFaculty}
                  options={departmentOptions}
                  value={professorDepartment}
                  placeholder="Search departments"
                  getOptionLabel={(option) => option.name}
                  onChange={(e, newValue) => handleDepartmentUpdate(newValue)}
                  renderOption={(props, option) => {
                    const { key, ...restProps } = props; // Extract and exclude the key from props
                    return (
                      <AutocompleteOption key={key} {...restProps}>
                        {option.name}
                      </AutocompleteOption>
                    );
                  }}
                />
                <FormHelperText>
                  {!professorFaculty ? "Select an Faculty to enable" : ""}
                </FormHelperText>
              </FormControl>
              <ProfessorContactFields
                initialName={formData.name}
                initialEmail={formData.email}
                onValidChange={handleFormChange}
                isSubmitted={isSubmitted}
              />
              <Box
                mt={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Box sx={{ display: 'flex', gap: '0.6rem'}}>
                    <Typography level="title-sm">Can't find the organization?&nbsp;
                    <Link href="/create-org">
                      Create Organization
                    </Link>
                    </Typography>
                </Box>
                <Button endDecorator={<ArrowForwardIcon/> } onClick={handleSave}>Invite Professor</Button>
              </Box>
              </Stack>
            </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
