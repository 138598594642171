import React from 'react';

const TextRenderer = ({ text }) => {
  if (!text) return null;

  // Split text into segments based on markdown patterns
  const segments = text.split(/((?:\*\*|__).+?(?:\*\*|__)|(?:\*|_).+?(?:\*|_)|`.*?`|(?:\+\+).+?(?:\+\+)|\d+\^-?\d+)/g);

  return (
    <>
      {segments.map((segment, index) => {
        // Bold text (** or __)
        if (segment.match(/^(?:\*\*|__).+(?:\*\*|__)$/)) {
          const content = segment.slice(2, -2);
          return <strong key={index}>{content}</strong>;
        }
        
        // Italic text (* or _)
        if (segment.match(/^(?:\*|_).+(?:\*|_)$/)) {
          const content = segment.slice(1, -1);
          return <em key={index}>{content}</em>;
        }

        // Underline text (++)
        if (segment.match(/^\+\+.+\+\+$/)) {
          const content = segment.slice(2, -2);
          return <u key={index}>{content}</u>;
        }
        
        // Inline code (`)
        if (segment.match(/^`.*?`$/)) {
          const content = segment.slice(1, -1);
          return (
            <code
              key={index}
              style={{
                backgroundColor: 'var(--joy-palette-neutral-300)',
                padding: '0.2em 0.4em',
                borderRadius: '3px',
                fontSize: '0.875em'
              }}
            >
              {content}
            </code>
          );
        }

        // Exponents (e.g., 10^9)
        if (segment.match(/^\d+\^-?\d+$/)) {
          const [base, exponent] = segment.split('^');
          return (
            <span key={index}>
              {base}
              <sup>{exponent}</sup>
            </span>
          );
        }

        // Plain text
        return segment;
      })}
    </>
  );
};

export default TextRenderer;