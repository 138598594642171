import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import {
  Accordion,
  AccordionGroup,
  AccordionSummary,
  AccordionDetails,
  Grid,
  List,
  ListItem,
  Stack,
  Typography,
} from "@mui/joy";
import QuestionAnswerCard from "./QuestionAnswerCard";
import ChatbotInputForm from "./form-elements/ChatbotInputForm";
import Container from "./Container";
import LogoIcon from "../assets/logoMobile.svg";
import CourseCheck from "./CourseCheck";
import useChatbot from "../hooks/useChatbot";
import { chatbotErrors } from "../config/chatbotErrors";
import { GeneralError, Initializing } from "./ChatbotStates";

const visuallyHidden = {
  border: 0,
  clip: "rect(0 0 0 0)",
  height: "1px",
  margin: "-1px",
  overflow: "hidden",
  padding: 0,
  position: "absolute",
  width: "1px",
  whiteSpace: "nowrap",
};

export default forwardRef(function Chatbot(props, ref) {
  const [index, setIndex] = useState(0);
  const [isSwitchingCourse, setIsSwitchingCourse] = useState(false);

  const {
    questionsAndAnswers,
    isInitializing,
    error,
    // setError,
    askQuestion,
    initializeChatbot,
  } = useChatbot();

  useImperativeHandle(ref, () => ({
    resetChatbot: async () => {
      setIsSwitchingCourse(true);
      initializeChatbot();
    },
  }));

  // Resets is switching once the new chatbot is initialized
  useEffect(() => {
    if (isInitializing === false) {
      setIsSwitchingCourse(false);
    }
  }, [isInitializing])

  // init on page load
  useEffect(() => {
    document.title = "Preview - All Day TA";
    initializeChatbot();
  }, [initializeChatbot]);

  const HelpfulTips = () => (
    <Grid container spacing={2} mt={1} xs={12} sx={{ alignItems: "flex-start" }}>
      <Grid>
        <img
          src={LogoIcon}
          alt=""
          style={{
            width: "3rem",
            height: "3rem",
            display: "block",
            marginLeft: "1.5rem",
          }}
        />
      </Grid>
      <Grid xs sx={{ height: "auto", flexGrow: "1" }}>
        <Typography level="h2" mb={2}>
          Preview your All Day TA
        </Typography>
        <Typography level="body-lg" mb={2}>
          I am the virtual TA you have trained with all of your readings, course
          materials, lecture content, and slides.{" "}
        </Typography>
        <Typography level="body-lg" ml={2}>
          Here are types of questions you can try:
        </Typography>
        <List
          component="ul"
          marker="disc"
          size="lg"
          sx={{ "--ListItem-minHeight": "36px" }}
        >
          <ListItem>When is the first assignment due?</ListItem>
          <ListItem>
            Can you explain <em>[this concept]</em> in relation to the
            lecture on <em>[this topic]</em>?
          </ListItem>
          <ListItem>
            In <em>[this situation]</em>, what would be the right approach
            if I wanted to <em>[do this]</em>?
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );

  if (isInitializing || isSwitchingCourse) return <Initializing message={isSwitchingCourse ? "Switching Course..." : "Loading All Day TA"} />;
  if (error === chatbotErrors.general_error) return <GeneralError />

  return (
    <CourseCheck>
      <Container>
        <Typography level="h1" sx={visuallyHidden}>
          Preview All Day TA
        </Typography>
        <Grid container direction="row" justifyContent="center" sx={{ flex: 1, overflowY: "auto", paddingBottom: "3rem" }}>
          <Grid xs={12} sm={12} md={8}>
            <Stack direction="column" spacing={3} alignItems="center">
              {questionsAndAnswers.length === 0 && <HelpfulTips />}
              {questionsAndAnswers.slice().reverse().map((qa) => (
                <QuestionAnswerCard
                  key={qa.id}
                  id={qa.id}
                  question={qa.question}
                  answer={qa.answer}
                  footnotes={qa.footnotes}
                  isLoading={qa.isLoading}
                  orgId={qa.question_log_org_id}
                  courseId={qa.question_log_course_id}
                  questionId={qa.question_log_question_id}
                  userId={qa.question_log_user_id}
                />
              ))}
            </Stack>
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="center" sx={{ position: "sticky", bottom: 0, zIndex: 100 }}>
          <Grid xs={12} sm={12} md={8} sx={{ textAlign: "right" }}>
            <ChatbotInputForm askQuestion={askQuestion} />
          </Grid>
        </Grid>
      </Container>
    </CourseCheck>
  );
});